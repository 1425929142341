import React, { useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Link } from 'react-router-dom'

import { CropTypeSetResponseRecord } from '../../../../store/api/api'
import { useStyles } from './styles'

interface Props {
  cropTypeSets?: CropTypeSetResponseRecord[]
  setCropTypeSet: (cropTypeSet?: CropTypeSetResponseRecord) => void
  currentCropTypeSet?: CropTypeSetResponseRecord
}

export const CropTypesControls: React.FC<Props> = ({ currentCropTypeSet, cropTypeSets, setCropTypeSet }) => {
  const classes = useStyles()

  useEffect(() => {
    if (cropTypeSets) {
      const cropTypeSet = cropTypeSets.find((cts) => cts.active) || cropTypeSets[0]
      setCropTypeSet(cropTypeSet)
    }
  }, [setCropTypeSet, cropTypeSets])

  if (!cropTypeSets) {
    return (<div>
      <Link to='/admin/crop-types/new'>Create New Set</Link>
    </div>)
  }

  return (
    <div className={classes.container}>
      <div>
        <InputLabel id='select-crop-type-set'>View Crop Type Set</InputLabel>
        <Select
          labelId='select-crop-type-set'
          value={currentCropTypeSet?.id || ''}
          onChange={(ev) =>
            setCropTypeSet(cropTypeSets.find((cts) => cts.id.toString() === ev.target.value?.toString()))
          }
        >
          {cropTypeSets.map((cropTypeSet) => {
            return (
              <MenuItem key={cropTypeSet.id} value={cropTypeSet.id}>
                {cropTypeSet.name}
              </MenuItem>
            )
          })}
        </Select>
      </div>
      <div>
        <Link to='/admin/crop-types/new'>Create New Set</Link>
      </div>
    </div>
  )
}
